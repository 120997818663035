import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationBaseDE from "./de/translation.json";
import translationBaseEN from "./en/translation.json";
import translationBaseIT from "./it/translation.json";
import translationBaseCS from "./cs/translation.json";

import translationGmDE from "./brands/gm/de/translation.json";
import translationGmEN from "./brands/gm/en/translation.json";
import translationGmIT from "./brands/gm/it/translation.json";
import translationGmCS from "./brands/gm/cs/translation.json";

let translationEN = {};
let translationDE = {};
let translationIT = {};
let translationCS = {};

translationEN = process.env.REACT_APP_TRANSLATION_PATH
  ? { ...translationBaseEN, ...translationGmEN }
  : translationBaseEN;
translationDE = process.env.REACT_APP_TRANSLATION_PATH
  ? { ...translationBaseDE, ...translationGmDE }
  : translationBaseDE;
translationIT = process.env.REACT_APP_TRANSLATION_PATH
  ? { ...translationBaseIT, ...translationGmIT }
  : translationBaseIT;
translationCS = process.env.REACT_APP_TRANSLATION_PATH
  ? { ...translationBaseCS, ...translationGmCS }
  : translationBaseCS;

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  cs: {
    translation: translationCS,
  },
};

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    partialBundledLanguages: true,
    fallbackLng: "en",
    debug: process.env.REACT_APP_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
