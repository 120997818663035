import { Address } from "app/models/Address";
import services from ".";
import { API } from "../constants/api.constant";
import { DeliveryOption } from "../models/DeliveryOption";
import { ProjectStatus } from "../models/Project";
import { ShippingOption } from "../models/ShippingOption";
import { TableFilter } from "../models/Table";
import fileDownload from "js-file-download";

export const create = async (name: string) => {
  try {
    const rs = await services.post(API.PROJECTS, {
      name,
      deliveryOption: DeliveryOption.ECONOMY,
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const createByName = async (name: string) => {
  try {
    const rs = await services.post(API.PROJECT_CREATE_BY_NAME, { name });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const createAutoName = async () => {
  try {
    const rs = await services.post(API.PROJECT_CREATE);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const list = async (params?: TableFilter) => {
  try {
    const rs = await services.get(API.PROJECTS_SEARCH, { params });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const detail = async (id: any) => {
  try {
    const rs = await services.get(API.PROJECT_DETAIL.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const viewByCode = async (accessCode: any) => {
  try {
    const rs = await services.get(
      API.PROJECT_VIEW_BY_CODE.replace(":accessCode", accessCode)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getDeliveryCosts = async (id: any) => {
  try {
    const rs = await services.get(
      API.PROJECT_DELIVERY_COSTS.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateDeliveryOption = async (data: {
  id?: any;
  deliveryOption: DeliveryOption;
}) => {
  try {
    const rs = await services.post(
      API.PROJECT_UPDATE_DELIVERY_OPTION.replace(":id", data.id),
      { deliveryOption: data.deliveryOption }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const checkout = async (data: {
  id: any;
  shippingOption: ShippingOption;
  shippingComment?: string;
  agreeTermConditions: boolean;
}) => {
  try {
    const rs = await services.post(
      API.PROJECT_CHECK_OUT.replace(":id", data.id),
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const offerProject = async (data: {
  id: any;
  email: string;
  shippingOption: ShippingOption;
  shippingComment?: string;
  agreeTermConditions: boolean;
}) => {
  try {
    const rs = await services.post(
      API.PROJECT_OFFER.replace(":id", data.id),
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const requestOffer = async (data: { id: any; deliveryDate: string }) => {
  try {
    const rs = await services.post(
      API.PROJECT_REQUEST_OFFER.replace(":id", data.id),
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateAddresses = async ({
  projectId,
  billingAddress,
  shippingAddress,
}: any) => {
  try {
    const rs = await services.post(API.PROJECT_UPDATE_ADDRESSES_CHECKOUT, {
      projectId,
      billingAddress,
      shippingAddress,
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const adaptPartPrice = async (data: { partId: number; unitPrice: number }) => {
  try {
    const rs = await services.post(API.PROJECT_ADAPT_PART_PRICE, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const adaptDeliveryDate = async (data: {
  projectId: any;
  deliveryDate: string;
}) => {
  try {
    const rs = await services.post(API.PROJECT_ADAPT_DELIVERY_DATE, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const sendOffer = async (id: any) => {
  try {
    const rs = await services.post(API.PROJECT_SEND_OFFER.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const acceptOffer = async (id: any) => {
  try {
    const rs = await services.post(
      API.PROJECT_APPROVE_OFFER.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const rejectOffer = async (id: any) => {
  try {
    const rs = await services.post(API.PROJECT_REJECT_OFFER.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const acceptOfferByAccessCode = async (accessCode: any) => {
  try {
    const rs = await services.post(
      API.PROJECT_APPROVE_OFFER_BY_ACCESS_CODE.replace(
        ":accessCode",
        accessCode
      )
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const rejectOfferByAccessCode = async (accessCode: any) => {
  try {
    const rs = await services.post(
      API.PROJECT_REJECT_OFFER_BY_ACCESS_CODE.replace(":accessCode", accessCode)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const resetAdapt = async (id: any) => {
  try {
    const rs = await services.post(API.PROJECT_RESET_ADAPT.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const countUnread = async ({
  statuses,
  selfCalculation = false,
  createdById,
}: {
  statuses: ProjectStatus[];
  selfCalculation?: boolean;
  createdById?: any;
}) => {
  try {
    const rs = await services.get(API.PROJECTS_COUNT_UNREAD, {
      params: {
        statuses: statuses.join(","),
        selfCalculation,
        createdById,
      },
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const markAsRead = async (id: any) => {
  try {
    const rs = await services.post(API.PROJECT_MARK_AS_READ.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getPrice = async (data: { id: any; shippingOption?: ShippingOption, country: string }) => {
  try {
    const rs = await services.get(
      API.PROJECT_PRICE_BY_DELIVERY_OPTION.replace(":id", data.id),
      {
        params: { shippingOption: data.shippingOption, country: data.country },
      }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const finalizeOrder = async ({
  id,
  shippingOption,
  shippingComment,
  agreeTermConditions = true,
}: {
  id: any;
  shippingOption: ShippingOption;
  shippingComment?: string;
  agreeTermConditions: boolean;
}) => {
  try {
    const rs = await services.post(
      API.PROJECT_FINALIZE_ORDER.replace(":id", id),
      {
        shippingOption,
        shippingComment,
        agreeTermConditions,
      }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const downloadPreviewOrder = async ({
  id,
  name,
}: {
  id: any;
  name: string;
}) => {
  try {
    const rs = await services.get(
      API.PROJECT_DOWNLOAD_ORDER.replace(":id", id),
      {
        responseType: "blob",
      }
    );
    fileDownload(rs.data, `${name}.pdf`);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateName = async (id: any, name: string) => {
  try {
    const rs = await services.patch(
      API.PROJECT_UPDATE_NAME.replace(":id", id),
      {
        name: name
      }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateStatusArchive = async (id: any) => {
  try {
    const rs = await services.patch(
      API.PROJECT_UPDATE_STATUS_ARCHIVE.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
}

const updateStatusUnArchive = async (id: any) => {
  try {
    const rs = await services.patch(
      API.PROJECT_UPDATE_STATUS_UNARCHIVE.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const deleteProject = async (id: any) => {
  try {
    const rs = await services.delete(
      API.PROJECT_DELETE.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const markAsPaid = async (id: any, paymentComment: any) => {
  try {
    const rs = await services.post(
      API.PROJECT_MARK_AS_PAID.replace(":id", id),
      { paymentComment }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const projectServices = {
  create,
  createByName,
  list,
  detail,
  viewByCode,
  getDeliveryCosts,
  updateDeliveryOption,
  checkout,
  offerProject,
  requestOffer,
  updateAddresses,
  adaptPartPrice,
  adaptDeliveryDate,
  sendOffer,
  acceptOffer,
  rejectOffer,
  acceptOfferByAccessCode,
  rejectOfferByAccessCode,
  countUnread,
  markAsRead,
  getPrice,
  resetAdapt,
  finalizeOrder,
  downloadPreviewOrder,
  updateName,
  updateStatusArchive,
  updateStatusUnArchive,
  deleteProject,
  markAsPaid,
  createAutoName
};

export default projectServices;
