import { configActions, configSelector } from "app/redux/slides/config.slide";
import configServices from "app/services/configServices";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
const capitalise = (input: any) => {
  return `${input[0].toUpperCase()}${input.slice(1).toLowerCase()}`
}
const getTitleSite = (t: any, key: string) => {
  switch (key) {
    case "ensinger":
      return "TECAPART | Ensinger";
    case "osl":
      return "OSL quotation";
    case "lst247":
      return "LST247 Shop";
    case "dev":
      return t("welcome.message") || "goCAD";
    default:
      return `${capitalise(key)} Shop`;
  }
};
function AppBrowserRouter (props: any) {
  const { t } = useTranslation();
  document.title = getTitleSite(t, process.env.REACT_APP_BRAND || "");
  const dispatch = useDispatch();
    const config = useSelector(configSelector);
    const getConfig = async () => {
      try {
        const rs = await configServices.getData();
        dispatch(configActions.setData(rs));
      } catch (error) {}
    };
    useEffect(() => {
      getConfig();
    }, []);
    useEffect(() => {
      const currentURL = window.location.href;
      if(config.subfolderFrontendUrl && currentURL.indexOf(process.env.PUBLIC_URL) == -1) {
        window.location.href = currentURL + config.subfolderFrontendUrl;
      }
    }, [config])
    return <BrowserRouter basename={process.env.PUBLIC_URL}>
    {props.children}
  </BrowserRouter>;
}
export default AppBrowserRouter;