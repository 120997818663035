import { WarningOutlined } from "@ant-design/icons";
import { ConfigProvider, Modal, notification } from "antd";
import csCZ from "antd/locale/cs_CZ";
import deDe from "antd/locale/de_DE";
import enUS from "antd/locale/en_US";
import itIT from "antd/locale/it_IT";
import "bootstrap-icons/font/bootstrap-icons.css";
import moment from "moment";
import "moment/locale/de";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "./App.scss";
import AppLoading from "./app/components/Loading";
import LoginRegister from "./app/components/LoginForm/LoginRegister";
import ResetPasswordForm from "./app/components/LoginForm/ResetPasswordForm";
import RegisterForm from "./app/components/Register/RegisterForm";
import RegisterSuccess from "./app/components/Register/RegisterSuccess";
import RegisterVerify from "./app/components/Register/RegisterVerify";
import { themes } from "./app/constants/theme.constant";
import i18n from "./app/locales/i18n";
import { AuthPage } from "./app/pages/Auth";
import { CalculationPartsPage } from "./app/pages/Calculation/CalculationParts";
import CustomerPage from "./app/pages/Customer";
import CustomerOverviewPage from "./app/pages/Customer/CustomerOverview";
import { Error404Page } from "./app/pages/Error/Error404";
import { MachineSettingsPage } from "./app/pages/Machine";
import { MainPage } from "./app/pages/Main";
import MaterialPage from "./app/pages/Material";
import { ProjectPage } from "./app/pages/Project";
import { ProjectAdaptPage } from "./app/pages/Project/ProjectAdapt";
import { ProjectDetailPage } from "./app/pages/Project/ProjectDetail";
import { SelfProjectsPage } from "./app/pages/Project/SelfProjects";
import { SecretPage } from "./app/pages/Secret";
import { BuyerSettingPage } from "./app/pages/Settings/BuyerSetting";
import { QuickSettingsPage } from "./app/pages/Settings/QuickSettings";
import { SellerSettingsPage } from "./app/pages/Settings/SellerSettings";
import SurfaceTreatmentPage from "./app/pages/SurfaceTreatment";
import { authActions, authSelector } from "./app/redux/slides/auth.slide";
import { langSelector } from "./app/redux/slides/locale.slide";
import { unreadActions } from "./app/redux/slides/unread.slide";
import { userActions } from "./app/redux/slides/user.slide";
import services, { setAuthorization } from "./app/services";
import userServices from "./app/services/user.service";
import "./index.css";
// import reCAPTCHA from "./app/utils/reCAPTCHA";
import * as Sentry from "@sentry/react";
import { User } from "app/models/User";
import { UserRole } from "app/models/UserRole";
import CompliancesPage from "app/pages/Compliances";
import { CookiesProvider, useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const wMobile = 767;
const wTablet = 1199;

const getSentryUrl = () => {
  if (
    process.env.REACT_APP_ENV === "production" &&
    process.env.REACT_APP_BRAND === "lst247"
  ) {
    return "https://2e2e850ec3553d17009a5394bd18e338@o4505501273292800.ingest.sentry.io/4506166613114880";
  }
  if (process.env.REACT_APP_ENV === "production") {
    return "https://26bafede506b4e848ec40555affde370@o4505501273292800.ingest.sentry.io/4505555303596032";
  }
  return "";
};

Sentry.init({
  dsn: getSentryUrl(),
  integrations:
    process.env.REACT_APP_ENV === "production"
      ? [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Breadcrumbs({
            fetch: true,
          }),
          new Sentry.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true,
          }),
        ]
      : [],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);



const getPrivatePolicyUrl = () => {
  switch (process.env.REACT_APP_BRAND) {
    case "lst247":
      return "https://lst-beuren.de/datenschutz.html";
    default:
      return "";
  }
};

export default function App() {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector(authSelector);
  const lang = useSelector(langSelector);
  const [cookies, setCookie, removeCookie] = useCookies(["jwtToken"]);
  setAuthorization(cookies.jwtToken || auth.jwtToken);
  const [loading, setLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [fontSize, setFontSize] = useState(
    window.screen.width > wMobile
      ? window.screen.width > wTablet
        ? (14 * window.screen.width) / 1920
        : window.matchMedia("(orientation: portrait)").matches
        ? (14 * window.screen.width) / 820
        : (14 * window.screen.width) / 1200
      : (14 * window.screen.width) / 375
  );
  const [isOpenDisconnected, setIsOpenDisconnected] = useState(false);
  const [isChatInit, setIsChatInit] = useState(false);

  const validateMessages = {
    required: t("validate.required"),
  };

  const antLocale = () => {
    switch (lang) {
      case "de":
        return deDe;
      case "it":
        return itIT;
      case "cs":
        return csCZ;
      default:
        return enUS;
    }
  };

  const onLogout = () => {
    removeCookie("jwtToken", {
      path: "/",
      sameSite: "strict",
    });
    setIsOpenDisconnected(false);
    setAuthorization(undefined);
    dispatch(authActions.clear());
    dispatch(unreadActions.clearData());
    navigate(process.env.PUBLIC_URL);
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      dispatch(userActions.setProfile(rs));

      if (rs) {
        initChatSupport(rs);
      }

      return rs;
    } catch (error) {}
  };

  const init = async () => {
    setLoading(true);
    if (cookies.jwtToken) {
      dispatch(authActions.loginWithCookie(cookies.jwtToken));
    }
    await i18n.changeLanguage(lang);
    services.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error) {
          if (error?.response?.data?.message) {
            const errorMessage = t(`error.${error.response.data.message}`, {
              defaultValue: "",
            });
            error.response.data.message = errorMessage ?? t("error.default");
          }
          if (
            error?.response?.data?.exception ===
            "io.jsonwebtoken.ExpiredJwtException"
          ) {
            setIsExpired(true);
          }
          if (error?.code === "ERR_NETWORK") {
            setIsOpenDisconnected(true);
          }
        }

        return Promise.reject(error?.response?.data);
      }
    );
    setLoading(false);
  };

  const initChatSupport = async (user: User) => {
    if (process.env.REACT_APP_CHAT_SUPPORT_ENABLE == "false" || !process.env.REACT_APP_SMART_SUPP_KEY || isChatInit) {
      return
    }
    const color = themes[process.env.REACT_APP_BRAND || "dev"].colorPrimary;

    setIsChatInit(true)

    const smartsupp = await import('smartsupp-widget');

    smartsupp.init(process.env.REACT_APP_SMART_SUPP_KEY, {
      color: color
    })

    if (user.email) {
      smartsupp.setEmail(user.email);
    }

    if (user.firstName) {
      smartsupp.setName(user.firstName);
    }

    if (user.phone) {
      smartsupp.setName(user.phone);
    }

    if (user.locale) {
      smartsupp.setLanguage(user.locale);
    }

    if (auth?.user) {
      smartsupp.setGroup(auth?.user.role);
    }

    smartsupp.chatClose()
  }

  useEffect(() => {
    moment.locale(lang);
    init();
  }, []);
  useEffect(() => {
    if (isExpired) {
      dispatch(authActions.setLastAccess(location.pathname));
      api.info({
        message: t("error.user.expired"),
        placement: "topRight",
      });
      onLogout();
    }
  }, [isExpired]);
  useEffect(() => {
    if (auth.isLogined && auth.jwtToken) {
      setIsExpired(false);
      setAuthorization(cookies.jwtToken || auth.jwtToken);
      getMe();
      if (auth.lastAccess) {
        setTimeout(() => {
          if (auth.lastAccess.indexOf(`/${auth.user?.role}`) > -1) {
            navigate(`${auth.lastAccess || `/${auth.user?.role || ""}`}`);
          } else {
            navigate(`/${auth.user?.role || ""}`);
          }
          dispatch(authActions.setLastAccess(""));
        }, 200);
      } else {
        if (location.pathname.indexOf(auth.user?.role) > -1) {
          setTimeout(() => {
            navigate(location.pathname);
          }, 200);
        } else {
          setTimeout(() => {
            navigate(`/${auth.user?.role || ""}`);
          }, 200);
        }
      }
    }
  }, [auth.isLogined, auth.jwtToken, auth.user]);
  if (loading) return <></>;
  return (
    <CookiesProvider>
      <ConfigProvider
        locale={antLocale()}
        theme={{
          token: {
            fontSize,
            ...(themes[process.env.REACT_APP_BRAND || "dev"] || themes.dev),
          },
        }}
        form={{
          validateMessages,
        }}
      >
        {contextHolder}
        <AppLoading />
        <div className={`app-version ${auth.isLogined ? "logined" : ""}`}>
          powered by{" "}
          <a href="https://gocad.de/" target="_blank" rel="noreferrer">
            goCAD.
          </a>
          <br/>
          {getPrivatePolicyUrl() && (
            <span>
              <a
                rel="noreferrer"
                target="_blank"
                className="ms-auto"
                href={getPrivatePolicyUrl()}
              >
                {t("privacyPolicy")}
              </a>
            </span>
          )}
        </div>
        {/* Routes nest inside one another. Nested route paths build upon
              parent route paths, and nested route elements render inside
              parent route elements. See the note about <Outlet> below. */}
        <SentryRoutes>
          {/* <Route path="counter" element={<Counter />} /> */}
          {!auth.isLogined && (
            <>
              <Route path={"/"} element={<AuthPage />}>
                <Route index element={<LoginRegister />} />
                <Route path="login" element={<LoginRegister />} />
                <Route path="register" element={<RegisterForm />} />
                <Route path="register/success" element={<RegisterSuccess />} />
                <Route path="verify" element={<RegisterVerify />} />
                <Route path="reset-password" element={<ResetPasswordForm />} />
              </Route>
              <Route path="/" element={<SecretPage />}>
                <Route path="projects/:id" element={<ProjectDetailPage />} />
                <Route
                  path="projects/viewByCode/:accessCode"
                  element={<ProjectDetailPage />}
                />
              </Route>
            </>
          )}

          {auth.isLogined && auth.jwtToken && auth.user && (
            <>
              <Route path="/" element={<MainPage />}>
                <Route index element={<ProjectPage />} />
              </Route>
              {auth.user?.role === UserRole.BUYER && (
                <Route path="/buyer" element={<MainPage />}>
                  <Route index element={<ProjectPage />} />
                  <Route path="" element={<ProjectPage />} />
                  <Route path="received-offers" element={<ProjectPage />} />
                  <Route path="requested-offers" element={<ProjectPage />} />
                  <Route path="completed" element={<ProjectPage />} />
                  <Route path="cancelled" element={<ProjectPage />} />
                  <Route path="archive" element={<ProjectPage />} />
                  <Route path="projects/:id" element={<ProjectDetailPage />} />
                  <Route
                    path="account-settings"
                    element={<BuyerSettingPage />}
                  />
                  <Route
                    path="projects/:projectId/calculations"
                    element={<CalculationPartsPage />}
                  />
                  <Route path="*" element={<Error404Page />} />
                </Route>
              )}
              {auth.user?.role === UserRole.SELLER && (
                <Route path="/seller" element={<MainPage />}>
                  <Route index element={<ProjectPage />} />
                  <Route path="sent-offers" element={<ProjectPage />} />
                  <Route path="completed" element={<ProjectPage />} />
                  <Route path="cancelled" element={<ProjectPage />} />
                  <Route path="projects/:id" element={<ProjectDetailPage />} />
                  <Route
                    path="projects/:id/adapt"
                    element={<ProjectAdaptPage />}
                  />
                  <Route path="customers" element={<CustomerPage />} />
                  <Route
                    path="customers/:id"
                    element={<CustomerOverviewPage />}
                  />
                  <Route path="my-projects" element={<SelfProjectsPage />} />
                  <Route
                    path="self-projects/archive"
                    element={<ProjectPage />}
                  />
                  <Route
                    path="self-projects/drafts"
                    element={<SelfProjectsPage />}
                  />
                  <Route
                    path="self-projects/dispatched-offers"
                    element={<SelfProjectsPage />}
                  />
                  <Route
                    path="projects/:projectId/calculations"
                    element={<CalculationPartsPage />}
                  />
                  <Route
                    path="account-settings"
                    element={<SellerSettingsPage />}
                  />
                  <Route
                    path="quick-settings"
                    element={<QuickSettingsPage />}
                  />
                  <Route path="material-settings" element={<MaterialPage />} />
                  <Route
                    path="machine-settings"
                    element={<MachineSettingsPage />}
                  />
                  <Route
                    path="surface-treatment-settings"
                    element={<SurfaceTreatmentPage />}
                  />
                  <Route
                    path="compliance-settings"
                    element={<CompliancesPage />}
                  />
                  <Route path="*" element={<Error404Page />} />
                </Route>
              )}
              {auth.user?.role === UserRole.ORDER_MANAGER && (
                <Route path="/order_manager" element={<MainPage />}>
                  <Route index element={<ProjectPage />} />
                  <Route path="sent-offers" element={<ProjectPage />} />
                  <Route path="completed" element={<ProjectPage />} />
                  <Route path="cancelled" element={<ProjectPage />} />
                  <Route path="projects/:id" element={<ProjectDetailPage />} />
                  <Route
                    path="projects/:id/adapt"
                    element={<ProjectAdaptPage />}
                  />
                  <Route path="customers" element={<CustomerPage />} />
                  <Route
                    path="customers/:id"
                    element={<CustomerOverviewPage />}
                  />
                  <Route path="my-projects" element={<SelfProjectsPage />} />
                  <Route
                    path="self-projects/archive"
                    element={<ProjectPage />}
                  />
                  <Route
                    path="self-projects/drafts"
                    element={<SelfProjectsPage />}
                  />
                  <Route
                    path="self-projects/dispatched-offers"
                    element={<SelfProjectsPage />}
                  />
                  <Route
                    path="projects/:projectId/calculations"
                    element={<CalculationPartsPage />}
                  />
                  <Route
                    path="account-settings"
                    element={<SellerSettingsPage />}
                  />
                  <Route path="*" element={<Error404Page />} />
                </Route>
              )}
            </>
          )}
          <Route path="*" element={<Error404Page />} />
        </SentryRoutes>
        <Modal
          open={isOpenDisconnected}
          cancelButtonProps={{
            hidden: true,
          }}
          onCancel={setIsOpenDisconnected.bind(null, false)}
          onOk={onLogout}
          okText={auth.isLogined ? t("logout") : t("ok")}
          centered
        >
          <div className="d-flex flex-column align-items-center">
            <WarningOutlined
              style={{ fontSize: "5rem" }}
              className="mb-3 text-danger"
            />
            <h5>Opps!</h5>
            <div className="sub-l">
              <i>Something went wrong.</i>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </CookiesProvider>
  );
}
